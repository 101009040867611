const routes = {
  path: "/membership",
  name: "membership",
  component: () =>
    import(
      /* webpackChunkName: "membership" */ "@/views/membership/MembershipMain"
    ),
  beforeEnter(to, from, next) {
    if (sessionStorage.getItem("token")) {
      next();
    } else {
      next({ name: "Login" });
    }
  },
  children: [
    {
      path: "mysummary",
      alias: "",
      name: "MySummary",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/membership/MySummary"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "라운딩 요약",
        m_subtitle: "라운딩 요약",
        menu_num: 1,
        sub_menu_num: 11,
        step: ["멤버십", "라운딩 요약"],
      },
    },
    {
      path: "myrecords",
      name: "MyRecords",
      component: () =>
        import(
          /* webpackChunkName: "membership" */ "@/views/membership/MyRecords"
        ),
      children: [
        {
          path: "mystatus",
          alias: "",
          name: "MyStatus",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyStatus"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 기록실",
            m_subtitle: "나의 기록실",
            menu_num: 1,
            sub_menu_num: 12,
            step: ["멤버십", "나의 기록실", "경기 기록"],
          },
        },
        {
          path: "mystatusview/:id",
          name: "MyStatusView",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyStatusView"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 기록실",
            m_subtitle: "나의 기록실",
            menu_num: 1,
            sub_menu_num: 12,
            step: ["멤버십", "나의 기록실", "경기 기록"],
          },
        },
        {
          path: "mytournaments",
          name: "MyTournaments",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournaments"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 기록실",
            m_subtitle: "나의 기록실",
            menu_num: 1,
            sub_menu_num: 12,
            step: ["멤버십", "나의 기록실", "대회 기록"],
          },
        },
        {
          path: "mytournamentsview/:id",
          name: "MyTournamentsView",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournamentsView"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 기록실",
            m_subtitle: "나의 기록실",
            menu_num: 1,
            sub_menu_num: 12,
            step: ["멤버십", "나의 기록실", "대회 기록"],
          },
        },
        {
          path: "mytournamentsattend/:id",
          name: "MyTournamentsAttend",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournamentsAttend"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 기록실",
            m_subtitle: "나의 기록실",
            menu_num: 1,
            sub_menu_num: 12,
            step: ["멤버십", "나의 기록실", "대회 참가 이력"],
          },
        },
        {
          path: "myscorecard/:id",
          name: "MyScorecard",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyScorecard"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 기록실",
            m_subtitle: "나의 기록실",
            menu_num: 1,
            sub_menu_num: 12,
            step: ["멤버십", "나의 기록실", "대회 기록"],
          },
        },
        {
          path: "mytournamentsranking/:id",
          name: "MyTournamentsRanking",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournamentsRanking"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 기록실",
            m_subtitle: "나의 기록실",
            menu_num: 1,
            sub_menu_num: 12,
            step: ["멤버십", "나의 기록실", "대회 기록"],
          },
        },
        {
          path: "myevent",
          name: "MyEvent",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyEvent"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 기록실",
            m_subtitle: "나의 기록실",
            menu_num: 1,
            sub_menu_num: 12,
            step: ["멤버십", "나의 기록실", "이벤트 기록"],
          },
        },
        {
          path: "myswing",
          name: "MySwing",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MySwing"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 기록실",
            m_subtitle: "나의 기록실",
            menu_num: 1,
            sub_menu_num: 12,
            step: ["멤버십", "나의 기록실", "스윙 기록"],
          },
        },
        {
          path: "myswingview/:id",
          name: "MySwingView",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MySwingView"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 기록실",
            m_subtitle: "나의 기록실",
            menu_num: 1,
            sub_menu_num: 12,
            step: ["멤버십", "나의 기록실", "스윙 기록"],
          },
        },
      ],
    },
    {
      path: "myaccount",
      name: "MyAccount",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/membership/MyAccount"
        ),
      children: [
        {
          path: "myprofile",
          alias: "",
          name: "MyProfile",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyProfile"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "회원정보관리",
            m_subtitle: "회원정보관리",
            menu_num: 1,
            sub_menu_num: 13,
            step: ["멤버십", "회원정보관리", "개인정보수정"],
          },
        },
        {
          path: "favoritelocation",
          name: "FavoriteLocation",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/FavoriteLocation"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "회원정보관리",
            m_subtitle: "회원정보관리",
            menu_num: 1,
            sub_menu_num: 13,
            step: ["멤버십", "회원정보관리", "단골매장"],
          },
        },
        {
          path: "favoritelocationinfo/:shopno",
          name: "FavoriteLocationInfo",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/FavoriteLocationInfo"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "회원정보관리",
            m_subtitle: "회원정보관리",
            menu_num: 1,
            sub_menu_num: 13,
            step: ["멤버십", "회원정보관리", "단골매장"],
          },
        },
        {
          path: "confirmpassword",
          name: "ConfirmPassword",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/ConfirmPassword"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "비밀번호 확인",
            m_subtitle: "비밀번호 확인",
            menu_num: 1,
            sub_menu_num: 13,
            step: ["멤버십", "회원정보관리", "회원탈퇴"],
          },
        },
        {
          path: "cancelmember",
          name: "CancelMember",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/CancelMember"
            ),
          props: true,
          password_yn: "",
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "회원정보관리",
            m_subtitle: "회원정보관리",
            menu_num: 1,
            sub_menu_num: 13,
            step: ["멤버십", "회원정보관리", "회원탈퇴"],
          },
        },
      ],
    },
    {
      path: "mybrandroom",
      name: "MyBrandRoom",
      component: () =>
        import(
          /* webpackChunkName: "membership" */ "@/views/membership/MyBrandRoom"
        ),
      children: [
        {
          path: "fittinglist",
          alias: "",
          name: "FittingList",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/FittingList"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 브랜드룸",
            m_subtitle: "나의 브랜드룸",
            menu_num: 1,
            sub_menu_num: 14,
            step: ["멤버십", "나의 브랜드룸", "피팅 리스트"],
          },
        },
        {
          path: "fittingswinglist/:fittingno",
          alias: "",
          name: "FittingSwingList",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/FittingSwingList"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 브랜드룸",
            m_subtitle: "나의 브랜드룸",
            menu_num: 1,
            sub_menu_num: 15,
            step: ["멤버십", "나의 브랜드룸", "스윙 보기"],
          },
        },
        {
          path: "fittingrequestwrite/:fittingno/:fitreqno?",
          name: "FittingRequestWrite",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/membership/FittingRequestWrite"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 브랜드룸",
            m_subtitle: "나의 브랜드룸",            
            menu_num: 1,
            sub_menu_num: 16,
            step: ["멤버십", "나의 브랜드룸", "피팅 신청"],
          },
        },
        {
          path: "fittinganswerview/:fitreqno",
          name: "FittingAnswerView",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/membership/FittingAnswerView"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 브랜드룸",
            m_subtitle: "나의 브랜드룸",  
            menu_num: 1,
            sub_menu_num: 17,
            step: ["멤버십", "나의 브랜드룸", "피팅 보기"],
          },
        },
        {
          path: "fittingsaleview/:fitansno",
          name: "FittingSaleView",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/membership/FittingSaleView"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "나의 브랜드룸",
            m_subtitle: "나의 브랜드룸", 
            menu_num: 1,
            sub_menu_num: 18,
            step: ["멤버십", "나의 브랜드룸", "구매 보기"],
          },
        },
      ],
    },
  ],
};

export default routes;
