const routes = {
  path: "/tournament",
  name: "토너먼트",
  component: () =>
    import(
      /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentMain"
    ),
  children: [
    {
      path: "tournamentschedule",
      alias: "",
      name: "TournamentSchedule",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentSchedule"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "토너먼트 일정",
        m_subtitle: "토너먼트 일정",
        menu_num: 2,
        sub_menu_num: 21,
        step: ["토너먼트", "토너먼트 일정"]
      }
    },
    {
      path: "tournamentscheduleview/:id",
      name: "TournamentScheduleView",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentScheduleView"
        ),
      children: [
        {
          path: "condition",
          alias: "",
          name: "Condition",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentCondition"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "토너먼트 일정",
            m_subtitle: "토너먼트 일정",
            menu_num: 2,
            sub_menu_num: 21,
            step: ["토너먼트", "토너먼트 요강"]
          }
        },
        {
          path: "ranking",
          name: "Ranking",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentRanking"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "토너먼트 일정",
            m_subtitle: "토너먼트 일정",
            menu_num: 2,
            sub_menu_num: 21,
            step: ["토너먼트", "토너먼트 순위"]
          }
        }
      ]
    },
    {
      path: "tournamentmember/:playerno",
      name: "TournamentMember",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentMember"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "토너먼트 일정",
        m_subtitle: "토너먼트 일정",
        menu_num: 2,
        sub_menu_num: 21,
        step: ["토너먼트", "토너먼트 일정"]
      }
    },
    {
      path: "jointournament",
      name: "JoinTournament",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/JoinTournament"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "토너먼트 참여안내",
        m_subtitle: "토너먼트 참여안내",
        menu_num: 2,
        sub_menu_num: 22,
        step: ["토너먼트", "토너먼트 참여안내"]
      }
    }
  ]
};

export default routes;
