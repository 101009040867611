<template>
  <div class="d-inline-block">
    <a
      v-if="nickname"
      @click="getMemberInfo(nickname, 'nickname')"
      class="more"
    >
      {{ nickname }}
    </a>
    <a
      v-if="playerid"
      @click="getMemberInfo(playerid, 'player_id')"
      class="more"
    >
      {{ playerid }}
    </a>
    <b-modal id="memberInfoModal" ref="memberInfoModal" size="lg" footer-class="btn-bottom-wrapper">
      <template #modal-header>
        <h3>회원정보</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="$bvModal.hide('memberInfoModal')"
        >
          <i class="material-icons">close</i>
        </button>
      </template>
      <div>
        <div class="p31_view">
          <p class="modal-subtitle">기본 정보</p>
          <div class="row g-0 border-top-dgray">
            <div class="col-lg-4 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center">닉네임</label>
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerbasicinfo && playerbasicinfo.nickname }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center">성별</label>
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerbasicinfo && changeGender(playerbasicinfo.gender) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center"> 가입일</label>
                </div>
                <div class="col d-table">
                  <p class="d-table-cell date">
                    {{ playerbasicinfo.date | GMTtuUTC }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-12 border-bottom">
              <div class="row g-0">
                <div class="col-lg-2 d-table bg-gray">
                  <label class="d-table-cell align-center">단골매장</label>
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerbasicinfo.shopname }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p class="modal-subtitle mt-4">라운딩 요약</p>
          <div class="row g-0 border-top-dgray">
            <div class="col-lg-6 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center">평균 퍼트 수</label>
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerpalyinfo.avggreenhit | toFixed}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center">총 라운딩 수</label>
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerpalyinfo.playcnt || 0 | comma }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-0">
            <div class="col-lg-6 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center"
                    >평균 드라이버 거리</label
                  >
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerpalyinfo.avgdriver | toFixed }} m
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center"
                    >페어웨이 안착률</label
                  >
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerpalyinfo.farewayrate | toFixed }} %
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-0">
            <div class="col-lg-6 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center">그린 적중율</label>
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerpalyinfo.greenrate | toFixed }} %
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center">파 세이브율</label>
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerpalyinfo.parsaverate | toFixed }} %
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-0">
            <div class="col-lg-6 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center">평균 타수</label>
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerpalyinfo.avgshot | toFixed | comma }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 border-bottom">
              <div class="row g-0">
                <div class="col d-table bg-gray">
                  <label class="d-table-cell align-center">최저 타수</label>
                </div>
                <div class="col d-table">
                  <p class="d-table-cell">
                    {{ playerpalyinfo.minshot | comma }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="text-red">* 18홀 정상 완료<!-- , 멀리건 없음, 고급 이상 --></span>
<!--       <template #modal-footer="{}">
        <button
          class="btn btn-primary btn-md btn1"
          type="button"
          aria-label="Close"
          @click="$bvModal.hide('memberInfoModal')"
        >
          닫기
        </button>
      </template> -->
      <template #modal-footer="{}">
        <b-button size="md" variant="outline-secondary" aria-label="닫기" @click="$bvModal.hide('memberInfoModal')"
          >닫기</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import ax from "@/api/tournament";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "MemberInfo",
  props: {
    nickname: String,
    playerid: String,
  },
  data() {
    return {
      // playerno: "",
      playerbasicinfo: [],
      playerpalyinfo: [],
    };
  },
  methods: {
    getMemberInfo(player, type) {
      ax.get_playerinfo(player, type, (flag, data) => {
        if (flag) {
          // this.playerno = playerno;
          this.playerbasicinfo = data.playerbasicinfo;
          this.playerpalyinfo = data.playerpalyinfo;
          this.$refs["memberInfoModal"].modalOuterStyle.zIndex = 9999;
          this.$refs["memberInfoModal"].show();
        } else {
          alert(data);
        }
      });
    },
  },
  mixins: [myMixin],
};
</script>
