const routes = {
  path: "/support",
  name: "support",
  component: () =>
    import(/* webpackChunkName: "support" */ "@/views/support/SupportMain"),
  children: [
    {
      path: "notice",
      alias: "",
      name: "Notice",
      component: () =>
        import(/* webpackChunkName: "support" */ "@/views/support/Notice"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "공지사항",
        m_subtitle: "공지사항",
        menu_num: 6,
        sub_menu_num: 61,
        step: ["고객지원", "공지사항"],
      },
    },
    {
      path: "noticeview/:id",
      name: "NoticeView",
      component: () =>
        import(/* webpackChunkName: "support" */ "@/views/support/NoticeView"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "공지사항",
        m_subtitle: "공지사항",
        menu_num: 6,
        sub_menu_num: 61,
        step: ["고객지원", "공지사항 보기"],
      },
    },
    {
      path: "inquiry",
      name: "Inquiry",
      component: () =>
        import(/* webpackChunkName: "support" */ "@/views/support/Inquiry"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "고객문의",
        m_subtitle: "고객문의",
        menu_num: 6,
        sub_menu_num: 62,
        step: ["고객지원", "고객문의"],
      },
    },
    {
      path: "resources",
      name: "Resources",
      component: () =>
        import(/* webpackChunkName: "support" */ "@/views/support/Resources"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "자료실",
        m_subtitle: "자료실",
        menu_num: 6,
        sub_menu_num: 63,
        step: ["고객지원", "자료실"],
      },
    },
    {
      path: "resourcesview/:id",
      name: "ResourcesView",
      component: () =>
        import(
          /* webpackChunkName: "support" */ "@/views/support/ResourcesView"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "자료실",
        m_subtitle: "자료실",
        menu_num: 6,
        sub_menu_num: 63,
        step: ["고객지원", "자료실 보기"],
      },
    },
  ],
};

export default routes;
