<template>
  <footer class="bg-black">
    <div class="wrap row g-0">
      <div class="col-6 f-menu">
        <router-link
          :to="{ name: 'Terms' }"
          >이용약관</router-link
        >
        <router-link
          :to="{ name: 'Privacy' }"
        >
          개인정보처리방침
        </router-link>
        <a href="mailto:info@xgolf.com.au">
          이메일문의
        </a>
        <img src="/img/logo_white.png" class="logo" />
        <p class="copyright">&copy; <span class="montserrat">X-GOLF All Rights Reserved.</span></p>
      </div>
      <div class="col-6">
        <ul class="sns">
          <li>
            <a
              href="https://www.youtube.com/channel/UCrJAub0AHpYrOWA2Iv6ZndQ"
              target="_blank"
              class="d-inline-block"
            >
              <img src="/images/ico_social1.png" class="img-fluid" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/Xgolfofficial-115944476859977"
              target="_blank"
              class="d-inline-block"
            >
              <img src="/images/ico_social3.png" class="img-fluid" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/xgolfofficial/"
              target="_blank"
              class="d-inline-block"
            >
              <img src="/images/ico_social4.png" class="img-fluid" />
            </a>
          </li>
          <li>
            <a
              href="https://medium.com/xgolf"
              target="_blank"
              class="d-inline-block"
            >
              <img src="/images/ico_social2.png" class="img-fluid" />
            </a>
          </li>
        </ul>
        <div
          class="family-site"
          :class="{ active: fs }"
          v-click-outside="fs_close"
        >
          <span @click="fs_view">Family Site</span>
          <div>
            <a href="http://www.thegolf.com" target="_blank" @click="fs_view">
              X-GOLF Headquarters
            </a>
            <a
              href="https://www.xgolfofficial.com"
              target="_blank"
              @click="fs_view"
            >
              X-GOLF Franchise
            </a>
            <a href="https://playxgolf.com" target="_blank" @click="fs_view">
              X-GOLF America
            </a>
            <a href="http://www.xgolf.com.au" target="_blank" @click="fs_view">
              X-GOLF AU
            </a>
            <a href="http://www.xgolf.ae" target="_blank" @click="fs_view">
              X-GOLF UAE
            </a>
          </div>
        </div>
      </div>
    </div>
    <a
      href="#wrap"
      class="gotop"
      :class="{ on: last_scroll_position > 300, bottom: is_bottom }"
    >
      <i class="material-icons">expand_less</i>
    </a>    
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      family: "",
      fs: false,
      last_scroll_position: 0,
      is_bottom: false,
    };
  },
  methods: {
    fs_view() {
      if (this.fs) this.fs = false;
      else this.fs = true;
    },
    fs_close() {
      this.fs = false;
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.last_scroll_position = currentScrollPosition;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.is_bottom = true;
      } else {
        this.is_bottom = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        // create event
        el.eventClickOutside = function (event) {
          // when target is not element or is not element childs
          if (!(el == event.target || el.contains(event.target))) {
            // call function
            vnode.context[binding.expression](event);
          }
        };
        // event binding
        document.body.addEventListener("click", el.eventClickOutside);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.eventClickOutside);
      },
    },
  },
};
</script>