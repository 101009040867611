<template>
  <div id="app">
    <!-- <notifications
      classes="my-notification"
      group="alert"
      position="bottom left"
      :duration="1000"
    ></notifications> -->
    <base-layout />
  </div>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout";

export default {
  name: "App",
  components: { BaseLayout },
  // watch: {
  //   $route() {
  //     window.scrollTo(0, 0);
  //   },
  // },
};
</script>
<style lang="scss">
.my-notification {
  padding: 10px;
  margin: 0 5px 5px;

  font-size: 23px;

  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;
}
.my-notification.warn {
  background: #ffb648;
  border-left-color: #f48a06;
}

.my-notification.error {
  background: #e54d42;
  border-left-color: #b82e24;
}

.my-notification.success {
  background: #68cd85;
  border-left-color: #42a85f;
}
</style>
