import Vue from "vue";

Vue.filter("comma", (value) => {
  //if (value) return String(value).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') // ios 오류
  //if (value) return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',') // 소수점도 , 찍힘
  if (value) {
    const parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else return 0;
});

Vue.filter("toFixed", (value, text) => {
  if (value) {
    return Number(value).toFixed(2);
  } else return text || 0;
});

Vue.filter("GMTtuUTCTIME", (value) => {
  if (value) {
    var date = new Date(value);
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    return new Date(now_utc)
      .toISOString()
      .replace("T", " ")
      .replace(/\..*/, "");
  } else {
    return "";
  }
});

Vue.filter("GMTtuUTC", (value) => {
  if (value) {
    var date = new Date(value);
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    return new Date(now_utc).toISOString().split("T")[0];
  } else {
    return "";
  }
});

Vue.filter("category", (category, type) => {
  console.log(type);
  if (category == 1) {
    return "매뉴얼";
  } else if (category == 2) {
    if (type === "list") return "홍보";
    else return "홍보 자료";
  } else if (category == 3) {
    if (type === "list") return "인쇄";
    else return "인쇄물";
  } else if (category == 4) {
    return "기타";
  } else {
    return "None";
  }
});

Vue.filter("get_img", (value) => {
  if (value) {
    return process.env.VUE_APP_S3_BUCKET_URL + "/" + value;
  } else {
    return "/img/no-img.png";
  }
});

Vue.filter("get_download", (value) => {
  if (value) {
    return process.env.VUE_APP_S3_BUCKET_URL + "/" + value;
  }
});

Vue.filter("get_club_type", (value) => {
  switch (value) {
    case "1":
      return "드라이버";

    case "2":
      return "우드";

    case "3":
      return "유틸리티";

    case "4":
      return "아이언";

    case "5":
      return "웨지";

    case "6":
      return "퍼터";
  }
});

Vue.filter("get_shaft_material", (value) => {
  switch (value) {
    case "1":
      return "스틸";

    case "2":
      return "그라파이트";

    case "3":
      return "카본";
  }
});

Vue.filter("get_balance", (value) => {
  switch (value) {
    case "1":
      return "R";

    case "2":
      return "SR";

    case "3":
      return "S";

    case "4":
      return "L";
  }
});

Vue.filter("get_interestclub", (value) => {
  switch (value) {
    case "1":
      return "타구감";

    case "2":
      return "방향감";

    case "3":
      return "비거리";
  }
});

Vue.filter("get_club_code", (value) => {
  switch (value) {
    case "1":
      return "DR";

    case "2":
      return "W2";

    case "3":
      return "I3";

    case "4":
      return "U4";

    case "5":
      return "PW";

    case "6":
      return "AW";

    default:
      return "ETC";
  }
});

Vue.filter("get_lesson_title", (value) => {
  switch (value) {
    case "lesson1":
      return "Address";

    case "lesson2":
      return "Back Swing";

    case "lesson3":
      return "Top Swing";

    case "lesson4":
      return "Down Swing";

    case "lesson5":
      return "Impact";

    case "lesson6":
      return "Follow Through";

    case "lesson7":
      return "Finish";

    case "lessontotal":
      return "총평";
  }
});
