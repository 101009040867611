var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"wrap"}},[_c('div',{class:{
      login:
        _vm.name === 'Login' ||
        _vm.name === 'Find_Id_Pwd' ||
        _vm.name === 'ConfirmPassword' ||
        _vm.name === 'Terms' ||
        _vm.name === 'Privacy',
      guide: _vm.name === 'JoinTournament',
      minigame: _vm.name === 'JoinMiniGame',
      terms: _vm.name === 'Terms',
      privacy: _vm.name === 'Privacy'
    },attrs:{"id":_vm.wrapId}},[_c('Header'),_vm._t("default")],2),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }