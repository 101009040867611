<template>
  <nav id="ddmenu" class="" :class="{ scroll: last_scroll_position > 0 }">
    <div class="wrap-1800">
      <div
        class="menu-icon"
        :class="[{ z91: mMenu }, { 'menu-icon-active': mMenu }]"
        @click="mmenu_on_off()"
      ></div>
      <h1>
        <router-link to="/">
          <img :src="logo" />
        </router-link>
      </h1>
      <ul :class="{ 'd-inline-block': mMenu }">
        <li class="ico">
          <router-link
            to="/login"
            class="color-black d-inline-block"
            v-if="!login"
          >
            <i class="material-icons d-block">power_settings_new </i>
            <span class="d-block">로그인</span>
          </router-link>
          <router-link to="/logout" class="color-black d-inline-block" v-else>
            <i class="material-icons d-block">power_settings_new</i>
            <span class="d-block">로그아웃</span>
          </router-link>
          <router-link
            to="/login"
            class="color-black d-inline-block"
            v-if="!login"
          >
            <i class="material-icons-outlined d-block">person</i>
            <span class="d-block">마이존</span>
          </router-link>
          <router-link
            to="/membership/myaccount"
            class="color-black d-inline-block"
            v-else
          >
            <i class="material-icons-outlined d-block">person</i>
            <span class="d-block">마이존</span>
          </router-link>
          <span class="mo_close" @click="mmenu_on_off">
            <img src="/img/ico_close.png" />
          </span>
        </li>
        <li
          :class="{ over: menu === 1 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(1)"
        >
          <span class="top-heading" @mouseover="menu_on(1)">
            <b :class="{ active: menu_num === 1 }">멤버십</b>
          </span>
          <div class="dropdown" v-if="menu === 1">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/membership/mysummary"
                    :class="{ active: sub_menu_num === 11 }"
                  >
                    라운딩 요약
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/membership/myrecords/mystatus"
                    :class="{ active: sub_menu_num === 12 }"
                  >
                    나의 기록실
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/membership/mybrandroom/fittinglist"
                    :class="{ active: sub_menu_num === 14 }"
                  >
                    나의 브랜드룸
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/membership/myaccount/myprofile"
                    :class="{ active: sub_menu_num === 13 }"
                  >
                    회원정보관리
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 2 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(2)"
        >
          <span class="top-heading" @mouseover="menu_on(2)">
            <b :class="{ active: menu_num === 2 }">토너먼트</b>
          </span>
          <div class="dropdown" v-if="menu === 2">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/tournament/tournamentschedule"
                    :class="{ active: sub_menu_num === 21 }"
                  >
                    토너먼트 일정
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/tournament/jointournament"
                    :class="{ active: sub_menu_num === 22 }"
                  >
                    토너먼트 참여안내
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 3 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(3)"
        >
          <span class="top-heading" @mouseover="menu_on(3)">
            <b :class="{ active: menu_num === 3 }">이벤트</b>
          </span>
          <div class="dropdown" v-if="menu === 3">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/event/netizenlesson/swinglist"
                    :class="{ active: sub_menu_num === 31 }"
                  >
                    네티즌 레슨
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/event/minigame"
                    :class="{ active: sub_menu_num === 32 }"
                  >
                    미니게임
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/event/winaminigame"
                    :class="{ active: sub_menu_num === 33 }"
                  >
                    미니게임 당첨
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/event/joinminigame"
                    :class="{ active: sub_menu_num === 34 }"
                  >
                    미니게임 참여안내
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 4 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(4)"
        >
          <span class="top-heading" @mouseover="menu_on(4)">
            <b :class="{ active: menu_num === 4 }">골프코스</b>
          </span>
          <div class="dropdown" v-if="menu === 4">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/golfcourse/courselist"
                    :class="{ active: sub_menu_num === 41 }"
                  >
                    코스안내
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 5 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(5)"
        >
          <span class="top-heading" @mouseover="menu_on(5)">
            <b :class="{ active: menu_num === 5 }">매장검색</b>
          </span>
          <div class="dropdown" v-if="menu === 5">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/findlocation/stores"
                    :class="{ active: sub_menu_num === 51 }"
                  >
                    매장안내
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 6 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(6)"
        >
          <span class="top-heading" @mouseover="menu_on(6)">
            <b :class="{ active: menu_num === 6 }">고객지원</b>
          </span>
          <div class="dropdown" v-if="menu === 6">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/support/notice"
                    :class="{ active: sub_menu_num === 61 }"
                    >공지사항</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/support/inquiry"
                    :class="{ active: sub_menu_num === 62 }"
                  >
                    고객문의
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/support/resources"
                    :class="{ active: sub_menu_num === 63 }"
                  >
                    자료실
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a href="" class="d-inline-block">
            <img src="/images/ico_social1.png" class="d-block w-100" />
          </a>
          <a href="" class="d-inline-block">
            <img src="/images/ico_social2.png" class="d-block w-100" />
          </a>
          <a href="" class="d-inline-block">
            <img src="/images/ico_social3.png" class="d-block w-100" />
          </a>
          <a href="" class="d-inline-block">
            <img src="/images/ico_social4.png" class="d-block w-100" />
          </a>
        </li>
      </ul>

      <div class="clearfix" v-if="!login">
        <router-link to="/login" class="color-white" v-if="icon_visible">
          <i class="material-icons d-block">power_settings_new</i>
          <span class="d-block">로그인</span>
        </router-link>
        <router-link to="/login" class="color-white" v-if="icon_visible">
          <i class="material-icons-outlined d-block">person</i>
          <span class="d-block">마이존</span>
        </router-link>
      </div>
      <div class="clearfix" v-else>
        <router-link to="/logout" class="color-white" v-if="icon_visible">
          <i class="material-icons d-block">power_settings_new</i>
          <span class="d-block">로그아웃</span>
        </router-link>
        <router-link
          to="/membership/mysummary"
          class="color-white"
          v-if="icon_visible"
        >
          <i class="material-icons-outlined d-block">person</i>
          <span class="d-block">마이존</span>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      menu: 0,
      mMenu: false,
      is_mobile: false,
      login: sessionStorage.getItem("token") ? true : false,
      last_scroll_position: 0,
      icon_visible: true,
    };
  },
  methods: {
    menu_on: function (eq) {
      if (this.is_mobile) return;
      this.menu = eq;
    },
    menu_on_m: function (eq) {
      if (!this.is_mobile) return;
      if (this.menu === eq) this.menu = 0;
      else this.menu = eq;
    },
    menu_off: function () {
      if (this.is_mobile) return;
      this.menu = 0;
    },
    mmenu_on_off: function () {
      if (this.mMenu === false) {
        this.menu = this.menu_num;
        this.mMenu = true;
      } else this.mMenu = false;
    },
    check_mobile: function () {
      let filter = "win16|win32|win64|mac|macintel";
      if (navigator.platform) {
        if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
          this.is_mobile = true;
        } else {
          this.is_mobile = false;
        }
      }
    },
    onScroll: function () {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.last_scroll_position = currentScrollPosition;
    },
    check_store: function () {
      if (sessionStorage.getItem("token")) {
        this.$store.dispatch("check_login");
      }
    },
    widthCheck() {
      if (window.innerWidth < 1000) {
        this.icon_visible = false;
      } else {
        this.icon_visible = true;
      }
    },
    handleResize(event) {
      if (window.innerWidth < 1000) {
        this.icon_visible = false;
      } else {
        this.icon_visible = true;
      }
    },
    resizeEvent() {
      window.addEventListener("resize", this.handleResize);
    },
  },
  mounted() {
    if (this.$store.state.status === "success") {
      this.login = true;
    }
    window.addEventListener("scroll", this.onScroll);
    this.resizeEvent();
  },
  beforeDestroy() {
    this.resizeEvent();
  },
  created() {
    this.check_mobile();
    this.check_store();
    this.widthCheck();
  },
  watch: {
    "$store.state.status": function () {
      this.login = this.$store.state.status === "success" ? true : false;
    },
    "$route.name": function () {
      this.mMenu = false;
    },
    "$route.meta.menu_num": function () {
      this.memu = this.$route.meta.menu_num;
    },
  },
  computed: {
    logo: function () {
      if (
        this.$route.name === "Login" ||
        this.$route.name === "Find_Id_Pwd" ||
        this.$route.name === "Terms" ||
        this.$route.name === "Privacy" ||
        this.$route.name === "ConfirmPassword" ||
        this.last_scroll_position > 0
      )
        return "/img/logo_black.png";
      else return "/img/logo_white.png";
    },
    menu_num: function () {
      return this.$route.meta.menu_num || "";
    },
    sub_menu_num: function () {
      return this.$route.meta.sub_menu_num || "";
    },
  },
};
</script>

<style lang="scss" scoped>
#ddmenu ul > li {
  vertical-align: top;
}
.z91 {
  z-index: 91;
}
#ddmenu ul.depth1 > li:last-of-type {
  display: none;
}
@media only screen and (max-width: 1200px) {
  #ddmenu .menu-icon-active {
    z-index: 91;
  }
  #ddmenu ul.depth1 > li:last-of-type {
    display: block;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
