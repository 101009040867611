const routes = {
  path: "/event",
  name: "event",
  component: () =>
    import(/* webpackChunkName: "event" */ "@/views/event/EventMain"),
  children: [
    {
      path: "netizenlesson",
      alias: "",
      name: "NetizenLesson",
      component: () =>
        import(/* webpackChunkName: "event" */ "@/views/event/NetizenLesson"),

      children: [
        {
          path: "swinglist",
          name: "SwingList",
          alias: "",
          component: () =>
            import(/* webpackChunkName: "event" */ "@/views/event/SwingList"),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "네티즌 레슨",
            m_subtitle: "네티즌 레슨",
            menu_num: 3,
            sub_menu_num: 31,
            step: ["이벤트", "네티즌 레슨"]
          }
        },
        {
          path: "netizenlessonview/:id",
          name: "NetizenLessonView",
          component: () =>
            import(
              /* webpackChunkName: "event" */ "@/views/event/NetizenLessonView"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: "네티즌 레슨",
            m_subtitle: "네티즌 레슨",
            menu_num: 3,
            sub_menu_num: 31,
            step: ["이벤트", "네티즌 레슨 보기"]
          }
        }
      ]
    },
    {
      path: "minigame",
      name: " MiniGame",
      component: () =>
        import(/* webpackChunkName: "event" */ "@/views/event/MiniGame"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "미니게임",
        m_subtitle: "미니게임",
        menu_num: 3,
        sub_menu_num: 32,
        step: ["이벤트", "미니게임"]
      }
    },
    {
      path: "winaminigame",
      name: "WinAMiniGame",
      component: () =>
        import(/* webpackChunkName: "event" */ "@/views/event/WinAMiniGame"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "미니게임 당첨",
        m_subtitle: "미니게임 당첨",
        menu_num: 3,
        sub_menu_num: 33,
        step: ["이벤트", "미니게임 당첨"]
      }
    },
    {
      path: "joinminigame",
      name: "JoinMiniGame",
      component: () =>
        import(/* webpackChunkName: "event" */ "@/views/event/JoinMinigame"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "미니게임 참여안내",
        m_subtitle: "미니게임 참여안내",
        menu_num: 3,
        sub_menu_num: 34,
        step: ["이벤트", "미니게임 참여안내"]
      }
    }
  ]
};

export default routes;