const routes = {
  path: "/findlocation",
  name: "findlocation",
  component: () =>
    import(
      /* webpackChunkName: "findlocation" */ "@/views/findlocation/FindlocationMain"
    ),
  children: [
    {
      path: "stores",
      alias: "",
      name: "Stores",
      component: () =>
        import(
          /* webpackChunkName: "findlocation" */ "@/views/findlocation/Stores"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "매장안내",
        m_subtitle: "매장안내",
        menu_num: 5,
        sub_menu_num: 51,
        step: ["매장검색", "매장안내"],        
      },
    },
    {
      path: "StoresView/:id",
      name: "StoresView",
      component: () =>
        import(
          /* webpackChunkName: "findlocation" */ "@/views/findlocation/StoresView"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "매장안내",
        m_subtitle: "매장안내",
        menu_num: 5,
        sub_menu_num: 51,
        step: ["매장검색", "매장정보"],        
      },
    },
  ],
};

export default routes;
