import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import _ from "lodash";
import auth from "@/api/auth";

import eventPaths from "./path.event";
import tournamentPaths from "./path.tournament";
import membershipPaths from "./path.membership";
import golfcoursePaths from "./path.golfcourse";
import supportPaths from "./path.support";
import findlocationPaths from "./path.findlocation";
// import userPaths from './path.user'
// import adminPaths from './path.admin'

Vue.use(VueRouter);

let allowNullName = ["Login", "Logout"];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    /*{
      path: '/',
      name: 'Splash',
      component: () => import(/* webpackChunkName: "login" * / '@/views/Splash'),
      meta: {
        layout: 'NoneLayout'
      }
    },*/
    {
      path: "/",
      name: "main",
      component: () => import(/* webpackChunkName: "main" */ "@/views/Main"),
      meta: {
        layout: "DefaultLayout",
        wrapId: "main",
      },
    },
    {
      path: "/login",
      name: "Login",
      beforeEnter(to, from, next) {
        console.log("to", to);
        console.log("from", from);

        if (
          from.meta &&
          to.meta &&
          to.meta.sub_menu_num !== from.meta.sub_menu_num
        ) {
          // console.log(1111);
          store.commit("setSearchData", {});
        }
        if (sessionStorage.getItem("token")) {
          next({ name: "main" });
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "login" */ "@/views/Login"),
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "로그인",
        m_subtitle: "로그인",
        step: ["로그인"],
      },
    },
    {
      path: "/logout",
      name: "Logout",
      beforeEnter: (to, from, next) => {
        store.dispatch("logout");
        next("/");
      },
    },
    {
      path: "/find_id_pwd",
      name: "Find_Id_Pwd",
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/Find_Id_Pwd"),
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "아이디/비밀번호 찾기",
        m_subtitle: "아이디/비밀번호 찾기",
        step: ["아이디/비밀번호 찾기"],
      },
    },
    {
      path: "/signup",
      name: "SignUp",
      component: () =>
        import(/* webpackChunkName: "signup" */ "@/views/SignUp"),
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "회원가입",
        m_subtitle: "회원가입",
        step: ["회원가입"],
      },
    },
    {
      path: "*",
      component: () => import(/* webpackChunkName: "404" */ "@/views/404"),
      meta: {
        layout: "NoneLayout",
      },
    },
    {
      path: "/terms_of_use",
      name: "Terms",
      component: () => import(/* webpackChunkName: "signup" */ "@/views/Terms"),
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "이용약관",
        m_subtitle: "이용약관",
        step: ["이용약관"],
      },
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: () =>
        import(/* webpackChunkName: "signup" */ "@/views/Privacy"),
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "개인정보 처리방침",
        m_subtitle: "개인정보 처리방침",
        step: ["개인정보 처리방침"],
      },
    },
  ],
});

router.addRoute(eventPaths);
router.addRoute(tournamentPaths);
router.addRoute(membershipPaths);
router.addRoute(golfcoursePaths);
router.addRoute(supportPaths);
router.addRoute(findlocationPaths);

// router.addRoute(userPaths)
// router.addRoute(adminPaths)
/*
router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/notFound')
  } else {
    if (_.includes(allowNullName, to.name)) {
      next()
    } else if (store.state.user && to.name === 'Splash') {
      next({
        path: '/dashboard',
        replace: true
      })
    } else if (!store.state.user && to.name === 'Splash') {
      next({
        path: '/dashboard',
        replace: true
      })
    } else if (store.state.user === null) {
      await auth.refreshToken((flag) => {
        if (flag) {
          next()
        } else {
          next({
            path: '/dashboard',
            replace: true
          })
        }
      })
    } else {
      next()
    }
  }
})
*/
export default router;